.datatable-style .outofstock {
    font-weight: 700;
    color: #FF5252;
    text-decoration: line-through;
}

.datatable-style .lowstock {
    font-weight: 700;
    color: #FFA726;
}

.datatable-style .instock {
    font-weight: 700;
    color: #66BB6A;
}

.datatable-style .row-dsr {
    /* background-color: rgb(24, 235, 17) !important; */
    background-color: #6ebc3b !important;
}

.datatable-style .row-escala {
    background-color: rgb(110, 179, 236) !important;
}

.datatable-style .row-justificativa {
    background-color: rgb(179, 174, 131) !important;
}

.datatable-style .row-feriado {
    background-color: rgb(255, 255, 0) !important;
}

.datatable-style .row-alerta {
    background-color: rgb(243, 124, 77) !important;
}

.datatable-style .row-movimentacaobh {
    background-color: rgb(95, 159, 159) !important;
}
                 